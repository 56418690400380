import React, { useState } from "react";
import { Link } from "react-router-dom";

import logo from "../assets/ace_logo.jpg";
import chaseLogo from "../assets/chase_logo.jpg";
// import Sidebar from "./sidebar/Sidebar";

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  var hamburgerMenu = document.querySelector(".hamburger-menu");
  var sideWidget = document.querySelector(".side-widget");

  // hamburgerMenu.addEventListener('click', function (e) {
  //   console.log("clicked")
  //   this.classList.toggle('open');
  //   sideWidget.classList.toggle('active');
  // });

  const onhandleClick = () => {
    console.log("clicked");
    hamburgerMenu.classList.toggle("open");
    sideWidget.classList.toggle("active");
  };

  return (
    <div>
      <aside className="side-widget">
        <div className="inner">
          <div className="logo">
            {" "}
            <a href="index-2">
              <img src={logo} alt="viewImageAlt" />
            </a>{" "}
          </div>

          <div className="hide-mobile">
            <p>
              Take the complexity out of customs Freight Solutions with customs
              brokerage services
            </p>
            <figure className="gallery">
              <Link to="/" data-fancybox>
                <img src={logo} alt="viewImageAlt" />
              </Link>
              <a href="images/recent-news02.jpg" data-fancybox>
                <img src="images/recent-news02.jpg" alt="viewImageAlt" />
              </a>
            </figure>
            <h6 className="widget-title">CONTACT INFO</h6>
            <address className="address">
              <p>
                +1 (708) 831 1605
                <br />
                <a href="mailto:info@FreightXpress.digital">
                  info@chasexpress.digital
                </a>
              </p>
            </address>
            <h6 className="widget-title">FOLLOW US</h6>
            <ul className="social-media">
              <li>
                <a href="index-2#">
                  <i className="lni lni-facebook-filled" />
                </a>
              </li>
              <li>
                <a href="index-2#">
                  <i className="lni lni-twitter-original" />
                </a>
              </li>
              <li>
                <a href="index-2#">
                  <i className="lni lni-instagram" />
                </a>
              </li>
              <li>
                <a href="index-2#">
                  <i className="lni lni-youtube" />
                </a>
              </li>
              <li>
                <a href="index-2#">
                  <i className="lni lni-pinterest" />
                </a>
              </li>
            </ul>
          </div>

          <div className="show-mobile">
            <div className="custom-menu">
              <ul>
                <li>
                  <a href="index-2#">En</a>
                </li>
                <li>
                  <a href="index-2#">Ru</a>
                </li>
              </ul>
            </div>

            <div className="site-menu">
              <ul>
                <li>
                  <a href="/logistics">Logistics</a>
                </li>
                <li>
                  <a href="/services">Services</a>
                </li>
                <li>
                  <a href="/about">About</a>
                </li>
                <li>
                  <a href="/contact">Contact</a>
                </li>
              </ul>
            </div>
          </div>

          <small>© 2022 Chase Xpress | Logicstic Shipping Company</small>
        </div>
      </aside>

      <div className="topbar">
        <div className="container">
          <div className="phone">
            Call Us Now: <a href="index-2#"> +1 (708) 831 1605 </a>
          </div>

          <div className="email">
            {" "}
            Email:{" "}
            <a href="mailto:info@FreightXpress.digital">
              info@chasexpress.digital
            </a>{" "}
          </div>

          {/* <div className="social-media">
            {" "}
            <span>Follow us</span>
            <ul>
              <li>
                <a href="index-2#">
                  <i className="lni lni-facebook-filled" />{" "}
                  <span>Facebook</span>
                </a>
              </li>
              <li>
                <a href="index-2#">
                  <i className="lni lni-twitter-original" />{" "}
                  <span>Twitter</span>
                </a>
              </li>
              <li>
                <a href="index-2#">
                  <i className="lni lni-linkedin-original" />{" "}
                  <span>Linkedin</span>
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>

      <nav className="navbar">
        <div className="container">
          <div className="inner">
            <div className="logo">
              {" "}
              <Link to="">
                {" "}
                <img src={chaseLogo} alt="viewImageAlt" />{" "}
              </Link>{" "}
            </div>

            <div className="custom-menu">
              <ul>
                <li>
                  <a href="index-2#">En</a>
                </li>
                <li>
                  <a href="index-2#">Ru</a>
                </li>
              </ul>
            </div>

            <div className="site-menu">
              <ul>
                <li>
                  <Link to="logistics">Logistics</Link>
                </li>
                <li>
                  <Link to="services">Services</Link>
                </li>
                <li>
                  <Link to="about">About</Link>
                </li>
                <li>
                  <Link to="contact">Contact</Link>
                </li>
              </ul>
            </div>

            <div className="hamburger-menu" onClick={onhandleClick}>
              {" "}
              <span /> <span /> <span />{" "}
            </div>

            <div className="navbar-button">
              {" "}
              <a href="index-2#">GET A QUOTE</a>{" "}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
