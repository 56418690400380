import React from "react";
// import testimonial from "../../assets/img/testimonial/testimonial-bg.jpg";
import { useState } from "react";

import { db } from "../../firebase/config";

import { doc, setDoc } from "firebase/firestore";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";

import "../jarvis.scss";

import slider1 from "../../assets/themezinho/qesco/images/slide01.jpg";

function Services() {
  // state for form
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (name === "" || email === "" || message === "") {
      alert("Please fill all fields.");
      return;
    }

    console.log("name", name);
    console.log("email", email);
    console.log("message", message);

    const data = {
      name,
      email,
      message,
    };

    console.log("data", data);

    // save to firebase using v9
    try {
      const docRef = doc(db, "contacts", data.name);
      await setDoc(docRef, data);

      console.log("Document written with ID: ", docRef.id);

      alert("Message sent successfully.");

      setName("");
      setEmail("");
      setMessage("");
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };
  return (
    <div>
      <header
        className="page-header"
        data-background="../themezinho.net/qesco/images/slide01.jpg"
        style={{
          backgroundImage: `url(${slider1})`,
        }}
      >
        <div className="container">
          <h1>Services</h1>
          <p>
            Take the complexity out of customs Freight Solutions
            <br />
            with customs brokerage services
          </p>
        </div>
      </header>
      <section className="content-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <figure>
                  <img src="images/section-title-shape.png" alt="Image" />
                </figure>
                <h2>
                  Reliable UK & Ireland Transport
                  <br />
                  Logistics Since 1973
                </h2>
              </div>
            </div>
            <div className="col-lg-6">
              <p>
                At Cardinal, we believe the supplier-vendor relationship to be
                an important, strong and long term partnership. Both the client
                and the logistics partners will concentrate on their lead
                competencies and work closely together with the common aim to be
                a leader in each other’s sector. We want to service you in a
                reliable, efficient and flexible manner, so that your company
                can compete in its business, not only by selling quality
                products, but also by satisfying customers with high value
                logistics services.
              </p>
              <p>
                With so many options available you can rest assured that we will
                be able to deliver your consignment, regardless of its size. And
                if there’s ever a time where you need some advice on choosing
                the right solution, our transport team, who have more than 120
                years’ experience.
              </p>
              <br />
            </div>
            <div className="col-lg-6">
              <p>
                Since our inception in 1998, we have developed according to our
                clients’ needs from a traditional freight forwarding company to
                a full service logistics provider. We provide all the necessary
                services for highly-engineered supply chain solutions and
                combine these with a range of warehousing and freight forwarding
                services to provide a complete solution.
              </p>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="service-box">
                <div className="inner">
                  <h6>
                    Supply Chain <br />
                    Solutions
                  </h6>
                  <figure>
                    <img src="images/icon04.png" alt="Image" />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="service-box">
                <div className="inner">
                  <h6>
                    3PL
                    <br />
                    Services
                  </h6>
                  <figure>
                    <img src="images/icon05.png" alt="Image" />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="service-box">
                <div className="inner">
                  <h6>
                    Internet <br />
                    Fulfilment
                  </h6>
                  <figure>
                    <img src="images/icon06.png" alt="Image" />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="service-box">
                <div className="inner">
                  <h6>
                    Global Freight <br />
                    Forwarding
                  </h6>
                  <figure>
                    <img src="images/icon07.png" alt="Image" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="content-section no-spacing" data-background="#f9f7ef">
        <div className="container">
          <div className="row align-items-center no-gutters">
            <div className="col-lg-6">
              <div className="side-content">
                <h2>Supply Chain Solutions</h2>
                <p>
                  At Cardinal, we’re passionate about helping our clients
                  operate their supply chains more effectively. To achieve this
                  we design and implement transformative solutions that are
                  customised for every individual client and their unique supply
                  chain challenges. Using an innovative approach to manage
                  supply chains we’ve given our clients true supply chain
                  visibility with revolutionary views of their supply chain. We
                  continually work towards achieving.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <figure className="side-image full-right">
                <img src="images/side-image02.jpg" alt="Image" />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section className="content-section no-spacing" data-background="#ffffff">
        <div className="container">
          <div className="row align-items-center no-gutters">
            <div className="col-lg-6">
              <figure className="side-image full-left">
                <img src="images/side-image02.jpg" alt="Image" />
              </figure>
            </div>
            <div className="col-lg-6">
              <div className="side-content right">
                <h2>Internet Fulfilment</h2>
                <p>
                  We pick, pack and dispatch your orders, to save you time and
                  money. We take away the hassle of storing, delivering and
                  handling any product returns and manage your fulfilment so you
                  can concentrate on your key business objectives.
                </p>
                <ul className="list">
                  <li>Dedicated secure facilities</li>
                  <li>Customs clearances</li>
                  <li>Online inventory reporting</li>
                  <li>Full order management & back office integration</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="content-section no-spacing" data-background="#212223">
        <div className="container">
          <div className="row align-items-center no-gutters">
            <div className="col-lg-6">
              <div className="side-content light">
                <h2>Global Freight Forwarding</h2>
                <p>
                  At Cardinal, we’re passionate about helping our clients
                  operate their supply chains more effectively. To achieve this
                  we design and implement transformative solutions that are
                  customised for every individual client and their unique supply
                  chain challenges. Using an innovative approach to manage
                  supply chains we’ve given our clients true supply chain
                  visibility with revolutionary views of their supply chain. We
                  continually work towards achieving.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <figure className="side-image full-right">
                <img src="images/side-image02.jpg" alt="Image" />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section className="content-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <figure>
                  <img src="images/section-title-shape.png" alt="Image" />
                </figure>
                <h2>Our Global World</h2>
                <p>Advice and best practice from our own team</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="image-content-box text-center">
                <figure>
                  <img src="images/service-image01.jpg" alt="Image" />
                </figure>
                <h6>Forwarding</h6>
                <p>
                  Our dedicated fleet of vehicles operates nationally throughout
                  the UK delivering both full, part, and consolidated loads.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="image-content-box text-center">
                <figure>
                  <img src="images/service-image02.jpg" alt="Image" />
                </figure>
                <h6>Supply Chain</h6>
                <p>
                  Sending smaller consignments of less than 10 pallets used to
                  be expensive business, but we have a solution for you
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="image-content-box text-center">
                <figure>
                  <img src="images/service-image03.jpg" alt="Image" />
                </figure>
                <h6>Forwarding</h6>
                <p>
                  In addition to our UK services, through our trusted and
                  fully-vetted network of partners, we offer a full import and
                  export service
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="content-section no-top-spacing">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="contact-bar">
                <h2>Contact us today!</h2>
                <p>
                  Contact us today for your airfreight <br />
                  requirements
                </p>
                <a href="services.html#">CLICK HERE TO CONTACT US!</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Services;
