/* eslint-disable react/no-unescaped-entities */

import { Link } from "react-router-dom";
import React from "react";
// import choose from "../assets/img/chooseus/chooseus-pic.png";
import slider1 from "../assets/themezinho/qesco/images/slide01.jpg";

import quoteImg from "../assets/themezinho/qesco/images/quote-image.jpg";
import quoteImg2 from "../assets/themezinho/qesco/images/section-bg-02.jpg";

function About() {
  return (
    <div>
      <header
        className="page-header"
        data-background="../themezinho.net/qesco/images/slide01.jpg"
        style={{
          backgroundImage: `url(${slider1})`,
        }}
      >
        <div className="container">
          <h1>About Us</h1>
          <p>
            Take the complexity out of customs Freight Solutions
            <br />
            with customs brokerage services
          </p>
        </div>
      </header>

      <section className="content-section">
        <div className="section-title">
          <figure>
            <img src="images/section-title-shape.png" alt="Imagee" />
          </figure>
          <h2>
            Everyone within our business <br />
            understands our mission
          </h2>
          <p>
            It means we don’t just come to work to do a job, we come to work to
            make a difference.
          </p>
        </div>

        <div
          className="image-quote"
          data-background="../themezinho.net/qesco/images/quote-image.jpg"
          style={{
            backgroundImage: `url(${quoteImg})`,
          }}
        >
          <div className="quote-box">
            <blockquote>
              “ To make the process of buying and managing shipping and
              logistics services simple, efficient, stress free and cost
              effective.”
            </blockquote>
            <p>
              We do this by providing a friendly, efficient and personalised
              service combined with innovative IT solutions that enable our
              customers to always be in control of their supply chains.
            </p>
          </div>
        </div>
      </section>

      <section className="content-section no-spacing">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="section-title text-left">
                <h6>Our Values</h6>
                <h2>
                  Giving customers <br />
                  a unique experience <br />
                  of partnership.{" "}
                </h2>
              </div>
            </div>

            <div className="col-lg-5 offset-lg-1">
              <div className="accordion" id="accordion" role="tablist">
                <div className="card">
                  <div className="card-header" role="tab" id="headingOne">
                    {" "}
                    <a
                      data-toggle="collapse"
                      href="about.html#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Provide a service we are proud of
                    </a>{" "}
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse show"
                    role="tabpanel"
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      We are committed to the long-term, profitable future of
                      our business and aim to achieve sustained growth that
                      provides enhanced value to customers and industry partners
                      and opportunities
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" role="tab" id="headingTwo">
                    {" "}
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      href="about.html#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      {" "}
                      Support each other and work together
                    </a>{" "}
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="headingTwo"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      {" "}
                      We are committed to the long-term, profitable future of
                      our business and aim to achieve sustained growth that
                      provides enhanced value to customers and industry partners
                      and opportunities
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" role="tab" id="headingThree">
                    {" "}
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      href="about.html#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      {" "}
                      Make long-term business decisions
                    </a>{" "}
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="headingThree"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      We are committed to the long-term, profitable future of
                      our business and aim to achieve sustained growth that
                      provides enhanced value to customers and industry partners
                      and opportunities
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" role="tab" id="headingFour">
                    {" "}
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      href="about.html#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      {" "}
                      Always seek to improve
                    </a>{" "}
                  </div>
                  <div
                    id="collapseFour"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="headingFour"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      We are committed to the long-term, profitable future of
                      our business and aim to achieve sustained growth that
                      provides enhanced value to customers and industry partners
                      and opportunities{" "}
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" role="tab" id="headingFive">
                    {" "}
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      href="about.html#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      {" "}
                      Member of the community
                    </a>{" "}
                  </div>
                  <div
                    id="collapseFive"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="headingFive"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      We are committed to the long-term, profitable future of
                      our business and aim to achieve sustained growth that
                      provides enhanced value to customers and industry partners
                      and opportunities{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-6">
              <div className="counter-box">
                {" "}
                <span className="odometer" data-count="175" data-status="yes">
                  0
                </span>
                <h6>UK operating centres</h6>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="counter-box">
                {" "}
                <span className="odometer" data-count="120" data-status="yes">
                  0
                </span>
                <h6>Countries Worldwide</h6>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="counter-box">
                {" "}
                <span className="odometer" data-count="50" data-status="yes">
                  0
                </span>
                <span className="symbol">K</span>
                <h6>Logistics Professionals</h6>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="counter-box">
                {" "}
                <span className="odometer" data-count="450" data-status="yes">
                  0
                </span>
                <span className="symbol">K</span>
                <h6>containers of freight</h6>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="counter-box">
                {" "}
                <span className="odometer" data-count="35" data-status="yes">
                  0
                </span>
                <span className="symbol">K</span>
                <h6>Project delivery vehicles</h6>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="counter-box">
                {" "}
                <span className="odometer" data-count="20" data-status="yes">
                  0
                </span>
                <span className="symbol">K</span>
                <h6>sq.ft of warehousing</h6>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content-section" data-background="#f9f7ef">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <figure>
                  <img src="images/section-title-shape.png" alt="Imagee" />
                </figure>
                <h2>
                  Chase Xpress UK LLC has grown from <br />
                  strength to strength
                </h2>
                <p>
                  It means we don’t just come to work to do a job, we come to
                  work to make a difference.
                </p>
              </div>
            </div>

            <div className="col-12">
              <ul className="history-box-list masonry">
                <li>
                  <div className="history-box">
                    <div className="description">
                      <h6>ISO ACCREDITATION</h6>
                      <p>
                        In November 2016 after a lot of work Atlas Logistics UK
                        Ltd became ISO 9001 and ISO 27001 accredited, complying
                        with The Provision of Import and Export Solutions and
                        Associated Services.{" "}
                      </p>
                    </div>

                    <small className="date">11-23-2016</small>
                  </div>
                </li>
                <li>
                  <div className="history-box">
                    <div className="description">
                      <h6>2016 RE BRAND</h6>
                      <p>
                        New logo, new website, fresh new brand but still the
                        same reliable team. Follow our blog article for more
                        information.{" "}
                      </p>
                    </div>

                    <small className="date">11-23-2016</small>
                  </div>
                </li>
                <li>
                  <div className="history-box">
                    <div className="description">
                      <h6> LOGISTICS PVT WERE</h6>
                      <p>
                        In September 2015 the shares of Logistics PVT were
                        acquired by the UK Company, meaning Atlas Logistics{" "}
                      </p>
                    </div>

                    <small className="date">11-23-2016</small>
                  </div>
                </li>
                <li>
                  <div className="history-box">
                    <div className="description">
                      <h6>2013 BOTH OFFICES</h6>
                      <p>
                        In March 2013 both offices moved to larger premises,
                        London being strategically placed close to Heathrow
                        Airport, and Birmingham moving to a facility giving the
                        Company access to 30,000 square feet of Logistics
                        warehousing.{" "}
                      </p>
                    </div>

                    <small className="date">11-23-2016</small>
                  </div>
                </li>
                <li>
                  <div className="history-box">
                    <div className="description">
                      <h6>2012 Chase Xpress UK LTD</h6>
                      <p>
                        In June 2012 Chase Xpress UK Ltd became members of the
                        WCA, the world’s largest and most powerful network of
                        independent freight forwarders,{" "}
                      </p>
                    </div>

                    <small className="date">11-23-2016</small>
                  </div>
                </li>
                <li>
                  <div className="history-box">
                    <div className="description">
                      <h6>LOGISTICS BEGAN TRADING</h6>
                      <p>
                        Logistics UK Ltd began trading in October 2009 as an
                        independent company, being associated through share
                        ownership{" "}
                      </p>
                    </div>

                    <small className="date">11-23-2016</small>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="content-section no-spacing">
        <div className="tab-content">
          <ul className="tab-nav">
            <li className="active">
              <a href="about.html#tab01">Postal</a>
            </li>
            <li>
              <a href="about.html#tab02">Retail</a>
            </li>
            <li>
              <a href="about.html#tab03">Logistics</a>
            </li>
            <li>
              <a href="about.html#tab04">Ticketing</a>
            </li>
            <li>
              <a href="about.html#tab05">Industrial</a>
            </li>
            <li>
              <a href="about.html#tab06">Other</a>
            </li>
          </ul>
          <div id="tab01" className="tab-item active-item">
            <div className="tab-inner">
              <figure>
                <img src="images/tab-image01.jpg" alt="Imagee" />
              </figure>
              <div className="content">
                <h3>Postal</h3>
                <p>
                  The retail industry is not only shaped by the quality of
                  services and goods, but also by economic trends that are
                  beyond an organization's control. In order to maximize
                  shareholder value, retailers constantly endeavor to optimize
                  their service and the flow of goods from the supply chain to
                  the store.{" "}
                </p>
                <p>
                  {" "}
                  Such dynamic optimization cannot be realized without proactive
                  handling of accurate data.
                </p>
              </div>
            </div>
          </div>

          <div id="tab02" className="tab-item">
            <div className="tab-inner">
              <figure>
                <img src="images/tab-image02.jpg" alt="Imagee" />
              </figure>
              <div className="content">
                <h3>Retail</h3>
                <p>
                  The retail industry is not only shaped by the quality of
                  services and goods, but also by economic trends that are
                  beyond an organization's control. In order to maximize
                  shareholder value, retailers constantly endeavor to optimize
                  their service and the flow of goods from the supply chain to
                  the store.{" "}
                </p>
                <p>
                  {" "}
                  Such dynamic optimization cannot be realized without proactive
                  handling of accurate data.
                </p>
              </div>
            </div>
          </div>

          <div id="tab03" className="tab-item">
            <div className="tab-inner">
              <figure>
                <img src="images/tab-image03.jpg" alt="Imagee" />
              </figure>
              <div className="content">
                <h3>Logistics</h3>
                <p>
                  The retail industry is not only shaped by the quality of
                  services and goods, but also by economic trends that are
                  beyond an organization's control. In order to maximize
                  shareholder value, retailers constantly endeavor to optimize
                  their service and the flow of goods from the supply chain to
                  the store.{" "}
                </p>
                <p>
                  {" "}
                  Such dynamic optimization cannot be realized without proactive
                  handling of accurate data.
                </p>
              </div>
            </div>
          </div>

          <div id="tab04" className="tab-item">
            <div className="tab-inner">
              <figure>
                <img src="images/tab-image04.jpg" alt="Imagee" />
              </figure>
              <div className="content">
                <h3>Ticketing</h3>
                <p>
                  The retail industry is not only shaped by the quality of
                  services and goods, but also by economic trends that are
                  beyond an organization's control. In order to maximize
                  shareholder value, retailers constantly endeavor to optimize
                  their service and the flow of goods from the supply chain to
                  the store.{" "}
                </p>
                <p>
                  {" "}
                  Such dynamic optimization cannot be realized without proactive
                  handling of accurate data.
                </p>
              </div>
            </div>
          </div>

          <div id="tab05" className="tab-item">
            <div className="tab-inner">
              <figure>
                <img src="images/tab-image05.jpg" alt="Imagee" />
              </figure>
              <div className="content">
                <h3>Industrial</h3>
                <p>
                  The retail industry is not only shaped by the quality of
                  services and goods, but also by economic trends that are
                  beyond an organization's control. In order to maximize
                  shareholder value, retailers constantly endeavor to optimize
                  their service and the flow of goods from the supply chain to
                  the store.{" "}
                </p>
                <p>
                  {" "}
                  Such dynamic optimization cannot be realized without proactive
                  handling of accurate data.
                </p>
              </div>
            </div>
          </div>

          <div id="tab06" className="tab-item">
            <div className="tab-inner">
              <figure>
                <img src="images/tab-image06.jpg" alt="Imagee" />
              </figure>
              <div className="content">
                <h3>Other</h3>
                <p>
                  The retail industry is not only shaped by the quality of
                  services and goods, but also by economic trends that are
                  beyond an organization's control. In order to maximize
                  shareholder value, retailers constantly endeavor to optimize
                  their service and the flow of goods from the supply chain to
                  the store.{" "}
                </p>
                <p>
                  {" "}
                  Such dynamic optimization cannot be realized without proactive
                  handling of accurate data.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="content-section image-bar"
        data-background="themezinho/qesco/images/section-bg-02.jpg"
        style={{
          backgroundImage: `url(${quoteImg2})`,
        }}
      >
        {" "}
      </section>

      <section className="content-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <figure>
                  <img src="images/section-title-shape.png" alt="Imagee" />
                </figure>
                <h2>Our accreditations </h2>
                <p>
                  We are committed to delivering professional services of the
                  highest standard
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-6">
              <div className="logo-item">
                {" "}
                <img src="images/logo01.jpg" alt="Imagee" />{" "}
              </div>
            </div>

            <div className="col-lg-3 col-6">
              <div className="logo-item">
                {" "}
                <img src="images/logo02.jpg" alt="Imagee" />{" "}
              </div>
            </div>

            <div className="col-lg-3 col-6">
              <div className="logo-item">
                {" "}
                <img src="images/logo03.jpg" alt="Imagee" />{" "}
              </div>
            </div>

            <div className="col-lg-3 col-6">
              <div className="logo-item">
                {" "}
                <img src="images/logo04.jpg" alt="Imagee" />{" "}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content-section no-spacing">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="gallery-item">
                {" "}
                <a href="images/gallery-item01.jpg" data-fancybox>
                  <img src="images/gallery-item01.jpg" alt="Imagee" />
                </a>{" "}
              </div>
            </div>

            <div className="col-md-6">
              <div className="row inner">
                <div className="col-md-6">
                  <div className="gallery-item">
                    {" "}
                    <a href="images/gallery-item02.jpg" data-fancybox>
                      <img src="images/gallery-item02.jpg" alt="Imagee" />
                    </a>{" "}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="gallery-item">
                    {" "}
                    <a href="images/gallery-item03.jpg" data-fancybox>
                      <img src="images/gallery-item03.jpg" alt="Imagee" />
                    </a>{" "}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="gallery-item">
                    {" "}
                    <a href="images/gallery-item04.jpg" data-fancybox>
                      <img src="images/gallery-item04.jpg" alt="Imagee" />
                    </a>{" "}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="gallery-item">
                    {" "}
                    <a href="images/gallery-item05.jpg" data-fancybox>
                      <img src="images/gallery-item05.jpg" alt="Imagee" />
                    </a>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="section-note">
        <div className="container">
          <h6>
            Barcodes, Inc. <strong>Logistics Facilities</strong> &amp; Technical
            Support to Continue Operations as Essential Services
          </h6>
        </div>
      </div>
    </div>
  );
}

export default About;
