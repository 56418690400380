/* eslint-disable react/no-unescaped-entities */

import React, { useState } from "react";
import { Link } from "react-router-dom";

import { doc, setDoc } from "firebase/firestore";
import { Swiper, SwiperSlide } from "swiper/react";
import { db } from "../firebase/config";

import Track from "../components/track/Track";

import "swiper/css";
import "./jarvis.scss";

import slider1 from "../assets/themezinho/qesco/images/slide01.jpg";
import slider2 from "../assets/images/slide02.jpg";
import slider3 from "../assets/themezinho/qesco/images/slide03.jpg";
import slider4 from "../assets/themezinho/qesco/images/section-bg-01.jpg";

import { useSwiper } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";

function Home() {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);

  const swiper = useSwiper();

  // state for form
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (name === "" || email === "" || message === "") {
      alert("Please fill all fields.");
      return;
    }

    console.log("name", name);
    console.log("email", email);
    console.log("message", message);

    const data = {
      name,
      email,
      message,
    };

    console.log("data", data);

    // save to firebase using v9
    try {
      const docRef = doc(db, "contacts", data.name);
      await setDoc(docRef, data);

      console.log("Document written with ID: ", docRef.id);

      alert("Message sent successfully.");

      setName("");
      setEmail("");
      setMessage("");
    } catch (error) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <div>
      <header className="slider">
        <div className="main-slider">
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            loop={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="swiper-wrapper"
          >
            <SwiperSlide className="swiper-slide">
              <div
                className="slide-image"
                data-background="themezinho/qesco/images/slide03.jpg"
                style={{
                  backgroundImage: `url(${slider1})`,
                }}
              />

              <div className="container">
                <h1>
                  Transport & <br />
                  Logistics
                </h1>
                <p>
                  Take the complexity out of customs Freight Solutions <br />
                  with customs brokerage services
                </p>
                <Link to="/services">COURIER SERVICE</Link>
              </div>
            </SwiperSlide>

            <SwiperSlide className="swiper-slide">
              <div
                className="slide-image"
                data-background="images/slide02.jpg"
                style={{
                  backgroundImage: `url(${slider2})`,
                }}
              />

              <div className="container">
                <h1>
                  Quickest & Safe <br />
                  Delivery
                </h1>
                <p>
                  Take the complexity out of customs Freight Solutions <br />
                  with customs brokerage services
                </p>
                <Link to="/services">DISCOVER ALL</Link>
              </div>
            </SwiperSlide>

            <SwiperSlide className="swiper-slide">
              <div
                className="slide-image"
                data-background="themezinho/qesco/images/slide03.jpg"
                style={{
                  backgroundImage: `url(${slider3})`,
                }}
              />

              <div className="container">
                <h1>
                  Allways <br />
                  Trustable
                </h1>
                <p>
                  Take the complexity out of customs Freight Solutions <br />
                  with customs brokerage services
                </p>
                <Link to="/about">MORE ABOUT US</Link>
              </div>
            </SwiperSlide>

            {/* <div className="controls">
              <div className="button-prev" onClick={() => swiper.slideNext()}>
                <i className="lni lni-chevron-left" />
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  // eslint-disable-next-line react/no-unknown-property
                  // xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="60"
                  height="60"
                  viewBox="0 0 30 30"
                  xmlSpace="preserve"
                >
                  <circle
                    fill="none"
                    stroke="#fff"
                    strokeWidth="1"
                    cx="15"
                    cy="15"
                    r="15"
                  />
                </svg>
              </div>

              <div className="swiper-pagination" />

              <div className="button-next" onClick={() => swiper.slideNext()}>
                <i className="lni lni-chevron-right" />
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  // xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="60"
                  height="60"
                  viewBox="0 0 30 30"
                  xmlSpace="preserve"
                >
                  <circle
                    fill="none"
                    stroke="#fff"
                    strokeWidth="1"
                    cx="15"
                    cy="15"
                    r="15"
                  />
                </svg>
              </div>
            </div> */}
          </Swiper>
        </div>
      </header>

      <Track />

      <div className="section-note">
        <div className="container">
          <h6>
            Barcodes, Inc. <strong>Logistics Facilities</strong> & Technical
            Support to Continue Operations as Essential Services
          </h6>
        </div>
      </div>

      <section className="content-section no-top-spacing">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <figure className="video-thumb">
                {" "}
                <img src="images/video-thumb.jpg" alt="Imagee" />{" "}
                <a href="videos/video01.mp4" data-fancybox>
                  Play Button
                </a>{" "}
              </figure>
            </div>

            <div className="col-lg-7">
              <div className="track-shipping-form">
                <div className="inner">
                  <h3>Track your shipment</h3>
                  <div className="form-group half">
                    <input type="radio" checked name="track" />
                    Shipping number
                  </div>

                  <div className="form-group half">
                    <input type="radio" name="track" />
                    Customer reference
                  </div>

                  <div className="form-group">
                    <a href="track/index.html">
                      <button type="submit">
                        <i className="lni lni-search-alt" /> TRACK
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content-section no-spacing">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="side-content">
                <h2>
                  Worldwide Logistics, Air Freight Forwarding, Road Haulage.
                </h2>
                <p>
                  Chase Xpress Logistics is a leading third party contract
                  logistics company based in the Midlands, United Kingdom. We
                  specialise in providing supply-chain warehousing and transport
                  services throughout the UK, Ireland and Continental Europe.
                </p>
                <a href="index-2.html#" className="custom-button">
                  Discover All Solutions
                </a>
              </div>
            </div>

            <div className="col-lg-5">
              <figure className="side-image">
                {" "}
                <img src="images/side-image01.jpg" alt="Image" />{" "}
              </figure>
            </div>
          </div>
        </div>
      </section>

      <section className="content-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="icon-box">
                <div className="inner">
                  <figure>
                    <img src="images/icon01.png" alt="Image" />
                  </figure>
                  <h6>Leadership</h6>
                  <p>
                    Experts who have extensive, hands-on experience in supply
                    chain management
                  </p>
                  <a href="index-2.html#" className="custom-link">
                    Connect with an Expert
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="icon-box">
                <div className="inner">
                  <figure>
                    <img src="images/icon02.png" alt="Image" />
                  </figure>
                  <h6>Technology</h6>
                  <p>
                    innovative and varied use of technology on the road, ocean,
                    railways, in the air.
                  </p>
                  <a href="index-2.html#" className="custom-link">
                    Connect with an Expert
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="icon-box">
                <div className="inner">
                  <figure>
                    <img src="images/icon03.png" alt="Image" />
                  </figure>
                  <h6>Solution</h6>
                  <p>
                    Global leaders in intermodal, less-than-truckload, supply
                    chain management.{" "}
                  </p>
                  <a href="index-2.html#" className="custom-link">
                    Connect with an Expert
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content-section" data-background="#f9f7ef">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <figure>
                  <img src="images/section-title-shape.png" alt="Image" />
                </figure>
                <h2>Global supply chain solutions</h2>
                <p>Dedicated specialists taking care of your products</p>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="solution-box">
                <figure>
                  <img src="images/solution-image01.jpg" alt="Image" />
                  <figcaption>
                    {" "}
                    <small>Solutions</small>
                    <h6>Food & Beverage</h6>
                    <a href="index-2.html#">Discover More</a>
                  </figcaption>
                </figure>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="solution-box">
                <figure>
                  <img src="images/solution-image02.jpg" alt="Image" />
                  <figcaption>
                    {" "}
                    <small>Solutions</small>
                    <h6>Global 4PL</h6>
                    <a href="index-2.html#">Discover More</a>
                  </figcaption>
                </figure>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="solution-box">
                <figure>
                  <img src="images/solution-image03.jpg" alt="Image" />
                  <figcaption>
                    {" "}
                    <small>Solutions</small>
                    <h6>Consumer Packaged Goods</h6>
                    <a href="index-2.html#">Discover More</a>
                  </figcaption>
                </figure>
              </div>
            </div>

            <div className="col-lg-4 offset-lg-2">
              <div className="solution-box">
                <figure>
                  <img src="images/solution-image04.jpg" alt="Image" />
                  <figcaption>
                    {" "}
                    <small>Solutions</small>
                    <h6>Transportation</h6>
                    <a href="index-2.html#">Discover More</a>
                  </figcaption>
                </figure>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="solution-box">
                <figure>
                  <img src="images/solution-image05.jpg" alt="Image" />
                  <figcaption>
                    {" "}
                    <small>Solutions</small>
                    <h6>Retails</h6>
                    <a href="index-2.html#">Discover More</a>
                  </figcaption>
                </figure>
              </div>
            </div>

            <div className="col-lg-2">
              <div className="solution-button">
                {" "}
                <a href="index-2.html#">
                  <i className="lni lni-chevron-right" />
                </a>{" "}
                <small>
                  DISCOVER
                  <br />
                  ALL SOLUTIONS
                </small>{" "}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content-section no-spacing">
        <div className="container">
          <div className="row align-items-center no-gutters">
            <div className="col-lg-6">
              <div className="side-services">
                <h6>Services</h6>
                <h2>
                  Quality delivered <br />
                  as standard{" "}
                </h2>
                <ul>
                  <li>
                    <a href="index-2.html#">
                      Forwarding <i className="lni lni-chevron-right" />
                    </a>
                  </li>
                  <li>
                    <a href="index-2.html#">
                      Supply Chain <i className="lni lni-chevron-right" />
                    </a>
                  </li>
                  <li>
                    <a href="index-2.html#">
                      Outsourcing <i className="lni lni-chevron-right" />
                    </a>
                  </li>
                  <li>
                    <a href="index-2.html#">
                      Technology <i className="lni lni-chevron-right" />
                    </a>
                  </li>
                </ul>
                <a href="index-2.html#" className="custom-link">
                  View All Services
                </a>
              </div>
            </div>

            <div className="col-lg-6">
              <figure className="side-image full-right">
                <div className="info-box">
                  <figure>
                    <img src="images/icon-infobox.png" alt="Image" />
                  </figure>
                  <p>
                    Personnel deliver bespoke solutions that are designed to
                    increase speed to market,{" "}
                    <strong>simplify inventory</strong> management, streamline
                    product flow and drive down costs.
                  </p>
                </div>

                <img src="images/side-image02.jpg" alt="Image" />
              </figure>
            </div>
          </div>
        </div>
      </section>

      <section className="content-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-6">
              <div className="counter-box">
                {" "}
                <span className="odometer" data-count="175" data-status="yes">
                  0
                </span>
                <h6>UK operating centres</h6>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="counter-box">
                {" "}
                <span className="odometer" data-count="120" data-status="yes">
                  0
                </span>
                <h6>Countries Worldwide</h6>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="counter-box">
                {" "}
                <span className="odometer" data-count="50" data-status="yes">
                  0
                </span>
                <span className="symbol">K</span>
                <h6>Logistics Professionals</h6>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="counter-box">
                {" "}
                <span className="odometer" data-count="450" data-status="yes">
                  0
                </span>
                <span className="symbol">K</span>
                <h6>containers of freight</h6>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="counter-box">
                {" "}
                <span className="odometer" data-count="35" data-status="yes">
                  0
                </span>
                <span className="symbol">K</span>
                <h6>Project delivery vehicles</h6>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="counter-box">
                {" "}
                <span className="odometer" data-count="20" data-status="yes">
                  0
                </span>
                <span className="symbol">K</span>
                <h6>sq.ft of warehousing</h6>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="content-section dark-overlay"
        data-background="../themezinho.net/qesco/images/section-bg-01.jpg"
        style={{
          backgroundImage: `url(${slider4})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="cta-bar">
                <h2>
                  We are now Logistics <br />
                  Industry Limited
                </h2>
                <p>
                  We’re one of the UK’s leading shipping and logistics
                  providers.
                </p>
                <a href="index-2.html#" className="custom-button">
                  Get A Quote
                </a>{" "}
                <a href="index-2.html#" className="light-button">
                  Estimate cost
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content-section no-bottom-spacing bottom-bg-half">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title text-left">
                <h6>TOTAL DELIVERY COST</h6>
                <h2>
                  Find out the approximate cost of <br />
                  delivery of your shipments
                </h2>
              </div>
            </div>

            <div className="col-12">
              <div className="calculator">
                <div className="row inner">
                  <div className="form-group col-md-6">
                    {" "}
                    <span>Distance</span>
                    <select>
                      <option>Please select</option>
                    </select>
                  </div>

                  <div className="form-group col-md-6">
                    {" "}
                    <span>Destination</span>
                    <input type="text" />
                  </div>

                  <div className="form-group col-lg-3 col-md-6">
                    {" "}
                    <span>Width</span>
                    <input type="text" />
                  </div>

                  <div className="form-group col-lg-3 col-md-6">
                    {" "}
                    <span>Height</span>
                    <input type="text" />
                  </div>

                  <div className="form-group col-lg-3 col-md-6">
                    {" "}
                    <span>Weight</span>
                    <input type="text" />
                  </div>

                  <div className="form-group col-lg-3 col-md-6">
                    {" "}
                    <span>Fragile</span>
                    <div className="yes-no" id="yes-no">
                      <input
                        type="radio"
                        name="rdo"
                        id="yes"
                        value="15000"
                        checked
                      />
                      <input type="radio" name="rdo" id="no" value="0" />
                      <div className="switch">
                        <label htmlFor="yes">Yes</label>
                        <label htmlFor="no">No</label>
                        <span />
                      </div>
                    </div>
                  </div>

                  <div className="form-group col-12">
                    <label>
                      <input type="checkbox" checked />
                      Express Delivery
                    </label>
                    <label>
                      <input type="checkbox" />
                      Insurance
                    </label>
                    <label>
                      <input type="checkbox" />
                      Packaging
                    </label>
                  </div>

                  <div className="form-group col-12">
                    <div className="estimated-price">
                      <b>Estimated Price :</b> <strong>$67.000</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content-section bg-half">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="testimonials-slider">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="testimonial">
                      <p>
                        As a first time importer from the Far East, the process
                        can be quite daunting. John Good has provided
                        exceptional service and support right through the
                        process.
                      </p>
                      <h6>Steve Macholnad </h6>
                      <small>Manager Smart Move LTD</small>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="testimonial">
                      <p>
                        As a first time importer from the Far East, the process
                        can be quite daunting. John Good has provided
                        exceptional service and support right through the
                        process.
                      </p>
                      <h6>Joshua Olenik</h6>
                      <small>Sales Team Acrostishe </small>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="testimonial">
                      <p>
                        As a first time importer from the Far East, the process
                        can be quite daunting. John Good has provided
                        exceptional service and support right through the
                        process.
                      </p>
                      <h6>Jack Abraham</h6>
                      <small>Director of Envato LLC</small>
                    </div>
                  </div>
                </div>

                <div className="swiper-pagination" />
              </div>
            </div>

            <div className="col-lg-6 offset-lg-1">
              <div className="section-title">
                <h2 style={{ color: "white" }}>
                  Trusted By Some The World's Companies for over 180 years
                </h2>
              </div>

              <div className="row inner">
                <div className="col-6">
                  <figure className="logo-item">
                    {" "}
                    <img src="images/logo01.jpg" alt="Image" />{" "}
                  </figure>
                </div>

                <div className="col-6">
                  <figure className="logo-item">
                    {" "}
                    <img src="images/logo02.jpg" alt="Image" />{" "}
                  </figure>
                </div>

                <div className="col-6">
                  <figure className="logo-item">
                    {" "}
                    <img src="images/logo03.jpg" alt="Image" />{" "}
                  </figure>
                </div>

                <div className="col-6">
                  <figure className="logo-item">
                    {" "}
                    <img src="images/logo04.jpg" alt="Image" />{" "}
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <figure>
                  <img src="images/section-title-shape.png" alt="Image" />
                </figure>
                <h2>Industry insight opinion</h2>
                <p>advice and best practice from our own team</p>
              </div>
            </div>

            <div className="col-md-6">
              <div className="recent-news">
                <figure>
                  <img src="images/recent-news01.jpg" alt="Image" />{" "}
                  <a href="index-2.html#">
                    <i className="lni lni-chevron-right" />
                  </a>{" "}
                </figure>
                <div className="content">
                  <h3>
                    <a href="index-2.html#">Carriers feel affect of COVID-19</a>
                  </h3>
                  <small>May 26th, 2020</small>
                  <p>
                    As the global pandemic continues to spread in certain parts
                    of the world, Europe as a whole is pushing to get back
                  </p>
                  <span>
                    <i className="lni lni-comments-alt" /> 0 Comments
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="recent-news">
                <figure>
                  <img src="images/recent-news02.jpg" alt="Image" />{" "}
                  <a href="index-2.html#">
                    <i className="lni lni-chevron-right" />
                  </a>
                </figure>
                <div className="content">
                  <h3>
                    <a href="index-2.html#">AEO – What are the advantages</a>
                  </h3>
                  <small>June 2nd, 2020</small>
                  <p>
                    Following the global pandemic and as businesses look to
                    recovery a review by many into the
                  </p>
                  <span>
                    <i className="lni lni-comments-alt" /> 0 Comments
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
