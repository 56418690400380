import { initializeApp, getApps, getApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
// use timestamp from firebase
import { Timestamp } from "firebase/firestore";

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyCMqyLm4c2ztIGRSlfWY1Nn8K77IwzsjCs",
  authDomain: "chasexpress-dd3d4.firebaseapp.com",
  projectId: "chasexpress-dd3d4",
  storageBucket: "chasexpress-dd3d4.appspot.com",
  messagingSenderId: "436853853636",
  appId: "1:436853853636:web:17e5db3588864134cfe891",
};

const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();

// init services
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, storage, auth };
