import { collection, getDocs, query } from "firebase/firestore";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase/config";
import "./track.scss";

const Track = () => {
  const [trackingID, setTrackingID] = useState("");
  const navigate = useNavigate();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const handleSearch = () => {
    // e.preventDefault();
    if (trackingID === "") {
      alert("Please enter a tracking ID");
      return;
    }

    console.log(trackingID);

    const fetchData = async () => {
      try {
        const q = query(collection(db, "tracking"));

        const res = await getDocs(q);

        if (res.empty) {
          alert("Sorry, we couldn't find that tracking number");
          setLoading(false);
        } else {
          const data = res.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

          const tracking = data.find((item) => item.id === trackingID);
          if (!tracking) {
            alert("Sorry, we couldn't find that tracking number");
            return;
          }
          console.log(tracking);
          if (tracking) {
            setData(tracking);
            navigate(`/tracking/${tracking.id}`);
            setLoading(false);
          } else {
            setData({ id: "", shipping: [] });
          }
          setData(tracking);
        }
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    fetchData();
    // handleModal()
  };

  return (
    <div className="track">
      <p className="track__heading">Track your Shipment</p>
      <div className="track__order">
        <div className="track__order__input">
          <input
            type="text"
            placeholder="Enter Tracking ID"
            value={trackingID}
            onChange={(e) => setTrackingID(e.target.value)}
          />
          <button onClick={() => handleSearch()}>Track Order</button>
        </div>
      </div>
    </div>
    // <form className="form-subscribe" onSubmit={handleSearch}>
    //   <input
    //     className="form-subscribe__input form-control"
    //     type="text"
    //     placeholder="enter your tracking code"
    //     required
    //     value={trackingID}
    //     onChange={(e) => setTrackingID(e.target.value)}
    //   />
    //   <button
    //     onClick={(e) => handleSearch(e)}
    //     className="form-subscribe__btn btn btn_mod-c btn-sm btn-effect"
    //   >
    //     <span className="btn__inner">Track Parcel</span>
    //   </button>
    // </form>
  );
};

export default Track;
