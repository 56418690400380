import { doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { db } from "../firebase/config";

import slider1 from "../assets/themezinho/qesco/images/slide01.jpg";

function Contact() {
  const [data, setData] = useState("");

  // state for form
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (name === "" || email === "" || message === "" || phone === "") {
      alert("Please fill all fields.");
      return;
    }

    const payload = {
      name,
      email,
      message,
      phone,
    };

    console.log("data", payload);

    // save to firebase using v9
    try {
      const docRef = doc(db, "contacts", payload.name);
      await setDoc(docRef, payload);

      console.log("Document written with ID: ", docRef.id);

      alert("Message sent successfully.");

      setName("");
      setEmail("");
      setMessage("");
    } catch (error) {
      console.error("Error adding document: ", e);
    }
  };
  return (
    <div>
      <header
        className="page-header"
        data-background="../themezinho.net/qesco/images/slide01.jpg"
        style={{
          backgroundImage: `url(${slider1})`,
        }}
      >
        <div className="container">
          <h1>Contact</h1>
          <p>
            Take the complexity out of customs Freight Solutions
            <br /> with customs brokerage services
          </p>
        </div>
      </header>

      <section className="content-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title">
                <figure>
                  <img src="images/section-title-shape.png" alt="Imagee" />
                </figure>
                <h2>We’d love to hear from you</h2>
                <p>
                  Please send your enquiry to <u>info@FreightXpress.digital</u>,
                  or contact your local
                  <br />
                  specialists using the contact details below.
                </p>
              </div>
            </div>

            <div className="col-lg-5 col-md-6">
              <div className="contact-box">
                <h5>LA Office (Head Office)</h5>
                <address>
                  Prospect Court Terrace Road Rawmarsh Rotherham State
                  <br />
                  South Yorkshire S62 6FN United Kingdom
                  <br />
                  Phone: +1 (708) 831 1605
                  <br />
                  Email:{" "}
                  <a href="mailto:info@chasexpress.digital">
                    info@chasexpress.digital
                  </a>
                </address>
                <a
                  href="https://goo.gl/maps/91vFMCUYDPY53chs5"
                  data-fancybox=""
                  data-width="640"
                  data-height="360"
                  className="custom-button"
                >
                  FIND US ON MAP
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="content-section no-bottom-spacing"
        data-background="#f9f7ef"
        style={{
          backgroundColor: "#f9f7ef",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title text-left">
                <h6>Have Any Question?</h6>
                <h2>
                  If you would like to know more <br />
                  about our services, please contact <br />
                  us using this form
                </h2>
              </div>
            </div>

            <form className="col-12" onSubmit={handleSubmit}>
              <div className="contact-form">
                <div className="row inner">
                  <div className="form-group col-lg-4">
                    <input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      placeholder="Complete Name"
                    />
                  </div>

                  <div className="form-group col-lg-4 col-md-6">
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="text"
                      placeholder="Email Address"
                    />
                  </div>

                  <div className="form-group col-lg-4 col-md-6">
                    <input
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      type="text"
                      placeholder="Phone No"
                    />
                  </div>

                  <div className="form-group col-12">
                    <textarea
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder="Message"
                    />
                  </div>

                  <div className="form-group col-12">
                    <input type="submit" value="SEND MESSAGE" />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>

      <div className="google-maps">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53097.27085393953!2d-118.09468860868989!3d33.71983005843659!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dd2685c0e76f15%3A0x3589a05fb515fe44!2sGiovanni%20Training!5e0!3m2!1sen!2sus!4v1657768643036!5m2!1sen!2sus"
          // style="border:0;"
          allowfullscreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>
    </div>
  );
}

export default Contact;
