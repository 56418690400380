import React from "react";
import { Link } from "react-router-dom";
import Track from "./track/Track";

import chaseLogo from "../assets/chase_logo.jpg";

// get current year
const year = new Date().getFullYear();

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <figure className="logo">
              {" "}
              <img src={chaseLogo} alt="ImageP" />{" "}
            </figure>
            <p>
              +1 (708) 831 1605
              <br />
              <a href="mailto:info@chasexpress.digital">
                info@chasexpress.digital
              </a>
            </p>
            <p>
              1. Prospect Court Terrace Road Rawmarsh Rotherham State: South
              Yorkshire S62 6FN United Kingdom
            </p>
          </div>
          <div className="col-lg-4 col-md-6">
            <h6 className="widget-title">Quick Links</h6>
            <ul className="footer-menu">
              <li>
                <Link to="/services">Air Freight</Link>
              </li>
              <li>
                <Link to="/services">Sea Freight</Link>
              </li>
              <li>
                <Link to="/services">Ground Cargo</Link>
              </li>
              <li>
                <Link to="/services">Packaging Options</Link>
              </li>
              <li>
                <Link to="/services">Track Your Shipment</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-4">
            <h6 className="widget-title">Subscribe Newsletter</h6>
            <form>
              <input type="email" placeholder="enter your email address...." />
              <input type="submit" value="Subscribe" />
            </form>
          </div>
          <div className="col-12">
            <div className="footer-bottom">
              {" "}
              <span>
                © {year} Chase Xpress | Logicstic Shipping Company
              </span>{" "}
              <span>
                {" "}
                <a href="contact.html#">Company Registration</a>
              </span>{" "}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
