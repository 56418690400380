import React from "react";
import { useParams } from "react-router-dom";
import { db } from "../firebase/config";
import { useState, useEffect } from "react";
import "./trackorder.scss";
import { collection, query, doc, onSnapshot } from "firebase/firestore";

import image from "../assets/media/bg/bg-title.jpg";

const TrackOrder = () => {
  const params = useParams();
  const { id } = params;
  const [trackingData, setTrackingData] = useState("");
  useEffect(() => {
    // SCROLL UP
    window.scrollTo(0, 0);

    try {
      const q = query(collection(db, "tracking"));

      onSnapshot(q, (querySnapshot) => {
        if (querySnapshot.empty) {
          console.log("empty");
        } else {
          const data = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          const tracking = data.find((item) => item.id === id);
          setTrackingData(tracking);
        }
      });
    } catch (err) {
      console.log(err.message);
    }
  }, [id]);

  const handlePrint = () => {
    window.print();
  };

  return (
    <div>
      <header
        className="page-header"
        data-background="../themezinho.net/qesco/images/slide01.jpg"
        style={{
          backgroundImage: `url(https://images.unsplash.com/photo-1485575301924-6891ef935dcd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80)`,
        }}
      >
        <div className="container">
          <h1>Track Your Order</h1>
          <p>
            Take the complexity out of customs Freight Solutions
            <br />
            with customs brokerage services
          </p>
        </div>
      </header>

      <div className="padding id-container">
        <div className="container">
          <div className="tracking-div">
            <h3 className="track-id-heading">Track Order: {id}</h3>
            <h4 className="track-heading">Delivery Info:</h4>
            <p className="delivery_details">{trackingData?.clientDetails}</p>

            {trackingData &&
              trackingData.extra.map((item, index) => (
                <div key={index} className="tracking-info-extra">
                  <p>
                    Time: <span>{item.pTime}</span>{" "}
                  </p>
                  <p>
                    Date: <span>{item.pDate}</span>{" "}
                  </p>
                  <p>
                    Comment: <span>{item.pComment}</span>
                  </p>
                </div>
              ))}
          </div>

          <div className="tracking-div">
            <h4 className="track-heading">Shipment History</h4>

            <table>
              <thead>
                <tr>
                  <th>Time</th>
                  <th>Location</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {trackingData &&
                  trackingData.shipping.map((item, index) => (
                    <tr key={index}>
                      <td>{item.time}</td>
                      <td>{item.location}</td>
                      <td>{item.status}</td>
                    </tr>
                  ))}
                {/* {trackingData?.shipping.length === 0 ?
                <tr>
                  <td colSpan="5">No Location hav been added</td>
                </tr> :
                null
              } */}
              </tbody>
            </table>
          </div>

          <div className="tracking-div">
            <h4 className="track-heading">Packages</h4>

            <table>
              <thead>
                <tr>
                  <th>Qty.</th>
                  <th>Type</th>
                  <th>Description</th>
                  <th>Length</th>
                  <th>Width</th>
                </tr>
              </thead>
              <tbody>
                {trackingData &&
                  trackingData.packaging.map((item, index) => (
                    <tr key={index}>
                      <td>{item.PQuantity}</td>
                      <td>{item.PType}</td>
                      <td>{item.PDesc}</td>
                      <td>{item.PLength}</td>
                      <td>{item.PWidth}</td>
                    </tr>
                  ))}
                {/* {trackingData?.packaging.length === 0 ?
                <tr>
                  <td colSpan="5">No Package hav been added</td>
                </tr> :
                null
              } */}
              </tbody>
            </table>
          </div>
          <div className="navbar-button" onClick={() => handlePrint()}>
            {" "}
            <p className="custom-buttonn">Print Tracking Info</p>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackOrder;
